<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div style="width: 40%">
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='initialDeliver1()'>新增</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "70%"}' :data='loadData' :showPagination="false" :showHeader=true>
        <span slot='address' slot-scope='text'>
          <template>
            <Ellipsis tooltip :length='80'>{{text}}</Ellipsis>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='添加IP' :destroyOnClose='true' :maskClosable='true' @ok="addWhiteListIp"
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
               ok-text='提交' cancel-text='取消'  :confirmLoading='deliver1.loading'>
        <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
          <a-form-model-item label='ip地址' prop='address'>
            <a-input v-model='deliver1.form.address' />
          </a-form-model-item>
        </a-form-model>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryInsidePosition, deleteInsidePosition,queryWhiteList,deleteWhiteList,addWhiteList  } from '@/api/inside'
import { mapGetters } from 'vuex'

const columns = [
  {
    width: 350,
    title: 'ip白名单',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' }
  },
  {
    width: 100,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'LimitPositionList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.currentUser = this.userInfo;
        return queryWhiteList({operator:this.currentUser.id})
          .then(datum => {
            return {
              records: datum
            }
          })
      },
      // loadData: parameter => {
      //   return queryWhiteList()
      //     .then(datum => {
      //       return datum;
      //       // {
      //       //   records: datum
      //       // }
      //     })
      // },
      deliver1: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          address: [{
            required: true,
            pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            message: '请填写正确的IP',
            trigger: 'change' }],
        },
      },
      currentUser: {}
    }
  },
  created() {
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    initialDeliver1() {
      this.deliver1.visible = true
      this.deliver1.loading = false
    },
    handleDelete(record) {
      deleteWhiteList({ id: record.id }).then(result => {
        this.$message.success('删除成功')
        this.$refs.table.refresh(true)
      })
    },
    addWhiteListIp(){
      this.$refs.form1.validate(result => {
        if (result) {
          addWhiteList(Object.assign({operator:this.currentUser.id,opUsername:this.currentUser.nickname},this.deliver1.form)).then(result=>{
            this.$message.success('添加成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
          });
        }
      });

    },
  }
}
</script>
